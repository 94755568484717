import React from "react";
import {
  STOREDATA,
  STOREAUTHTOKEN,
  STOREACCOUNTDATA,
  BASIC_DETAILS,
  OFFER_DETAIS,
  RESET_CONTEXT,
  DISBURSALDETAILSSORTEDARRAY,
  FILTERARRAYCHANGEDROPDOWN,
} from "../action/action.type";
const myNewState = JSON.parse(sessionStorage.getItem("dataMapping"));
const reducer = (state = [], action) => {
  switch (action.type) {
    case STOREDATA:
      let newState = {
        ...state,
        [action.name]: action.payload,
      };
      // return {
      //     ...state, ...newState
      // }
      console.log(newState);
      // !myNewState
      //   ? sessionStorage.setItem("dataMapping", JSON.stringify(newState))
      //   : sessionStorage.setItem("dataMapping", JSON.stringify(myNewState));
      sessionStorage.setItem("dataMapping", JSON.stringify(newState));
      return newState;
    case FILTERARRAYCHANGEDROPDOWN:
      //context for custom dropdown changes
      let filterarraychangeDropdown = { ...state }
      filterarraychangeDropdown["filterarraychangeDropdown"] = {
        ...filterarraychangeDropdown["filterarraychangeDropdown"],
        [action.payload.contractNumber]: action.payload.response,
        [action.payload.flag]: action.payload.value
      }
      sessionStorage.setItem("dataMapping", JSON.stringify(filterarraychangeDropdown));
      console.log(filterarraychangeDropdown)
      return filterarraychangeDropdown;
    case STOREAUTHTOKEN:
      let authToken = {
        ...state,
        [action.name]: action.payload,
      };
      // !myNewState
      //   ? sessionStorage.setItem("dataMapping", JSON.stringify(authToken))
      //   : sessionStorage.setItem("dataMapping", JSON.stringify(myNewState));
      sessionStorage.setItem("dataMapping", JSON.stringify(authToken));
      return authToken;
    case OFFER_DETAIS:
      let offerDetails = {
        ...state,
        [action.name]: action.payload,
      };
      sessionStorage.setItem("dataMapping", JSON.stringify(offerDetails));
      return offerDetails;
    case DISBURSALDETAILSSORTEDARRAY:
      let disbursalDetailsSortedArray = { ...state }
      disbursalDetailsSortedArray["disbursalDetailsSortedArray"] = {
        ...disbursalDetailsSortedArray["disbursalDetailsSortedArray"],
        [action.payload.contractNumber]: action.payload.response,
      }
      sessionStorage.setItem("dataMapping", JSON.stringify(disbursalDetailsSortedArray));
      return disbursalDetailsSortedArray;
    case STOREACCOUNTDATA:
      // let accountData = {
      //     ...state,
      //     [action.name]: [
      //         action.payload.contractNumber = action.payload.response
      //     ]
      // }
      let accountData = {
        ...state,
        [action.name]: state?.[action.name] ? state?.[action.name] : {},
      };
      accountData[action.name][action.payload.contractNumber] =
        action.payload.response;
      // !myNewState
      //   ? sessionStorage.setItem("dataMapping", JSON.stringify(accountData))
      //   : sessionStorage.setItem("dataMapping", JSON.stringify(myNewState));
      sessionStorage.setItem("dataMapping", JSON.stringify(accountData));
      console.log(accountData);
      return accountData;
    case BASIC_DETAILS:

      let newBasicDetails = { ...state }
      newBasicDetails[action?.name][action?.payload?.contractNumber] = {
        ...newBasicDetails[action?.name][action?.payload?.contractNumber],
        [action?.keyName]: action?.payload?.response
      }
      return newBasicDetails
    case RESET_CONTEXT:
      sessionStorage.setItem("dataMapping", JSON.stringify([]))
      return []

    default:
      sessionStorage.setItem("dataMapping", JSON.stringify(state));
      return state;
  }
};
export default reducer;

import React, { useEffect, useState } from "react";
import "./CustomDropDown.scss";
import { useContext } from "react";
import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import { THEME_DARK } from "../../context/ThemeContext/actions";
import { useRef } from "react";
import PropTypes from 'prop-types';
import DataMapping from "../../context/data-mapping";
import { FILTERARRAYCHANGEDROPDOWN, STOREDATA } from "../../context/data-mapping/action/action.type";
import { useLocation } from "react-router-dom";

const CustomDropDown = ({
  className,
  optionArray,
  optionState,
  setOptionState,
  selectedOption,
  setSelectedOption,
  labelFlag,
  labelText,
  labelClass,
  setActiveTab,
  storeSelectAccNumberData,
  bankLas,
  setEmployementType,
  ccodAccount,
  wealthBankAccount,
  asterisk,
  callGetDisbursalDetails,
  error,
  errorSpace,
  handleDropDownChange,
  placeholderClass,
  bankingDetails,
}) => {
  const [themeState] = useContext(ThemeContext);
  const dropDownRef = useRef();
  const [data, dispatchData] = useContext(DataMapping);
  const [accountNumberChange, setAccountNumberChange] = useState(false);
  const location = useLocation();
  const handdleOptionChange = (obj) => {
    setAccountNumberChange(true)
    setSelectedOption(obj.value);

    // function to handle dropdown click and dispatching filter array
    const handleDropdownChange = (accountArray, accountKey, objValue) => {
      const filteredArray = accountArray.filter(item => item?.[accountKey] === objValue);
      if (filteredArray.length > 0) {
        console.log(filteredArray[0]);
        dispatchData({
          type: FILTERARRAYCHANGEDROPDOWN,
          name: "filterarraychangeDropdown",
          payload: {
            response: filteredArray[0],
            contractNumber: data?.currentActiveData?.accountNumber,
            flag: "flagshowCustomerDetailsChangeDropdown",
            value: true
          }
        });
      }
    };

    // Main logic
    const system = data?.currentActiveData.system.toLowerCase();
    const accountNumber = data?.currentActiveData.accountNumber;

    if (system === "ccod" && ccodAccount) {
      // Handle ccod profile banking details section dropdown change logic
      dispatchData({
        name: "clickdropdown",
        payload: !data?.clickdropdown,
        type: STOREDATA
      });

      const filterArray = data?.disbursalDetailsSortedArray[accountNumber];
      if (filterArray) {
        handleDropdownChange(filterArray, 'BANK_ACC_NO', obj.value);
      }
    } else if (system === "wealth" && wealthBankAccount) {
      // Handle wealth profile banking details section dropdown change logic
      dispatchData({
        name: "clickdropdown",
        payload: !data?.clickdropdown,
        type: STOREDATA
      });

      const wealthBankingDetailsCollection = data?.basicDetails[accountNumber]?.clientProfileDetails[0]?.BankAccountCollection;
      if (wealthBankingDetailsCollection) {
        handleDropdownChange(wealthBankingDetailsCollection, 'AccountNo', obj.value);
      }
    }

    if (setActiveTab) {
      setActiveTab(obj.id);
    }
    if (storeSelectAccNumberData) {
      storeSelectAccNumberData(obj.value)
    }
    if (setEmployementType) {
      setEmployementType(obj.value)
    }
    if (callGetDisbursalDetails) {
      callGetDisbursalDetails(obj.id)
    }
    if (handleDropDownChange) {
      handleDropDownChange(obj.value)
    }
  };


  useEffect(() => {
    const onBodyClick = (e) => {
      if (!dropDownRef.current?.contains(e.target)) {
        setOptionState(false)
      }
    }
    document.body.addEventListener('click', onBodyClick);
    return () => {
      document.body.removeEventListener('click', onBodyClick);
    }
  }, [])

  useEffect(() => {
    if (data?.currentActiveData?.system?.toLowerCase() == "ccod") {
      if (data?.disbursalDetailsSortedArray) {
        if (ccodAccount && optionArray) {
          setSelectedOption(optionArray[0]?.value);
        }
      }
    } else if (data?.currentActiveData?.system?.toLowerCase() == "wealth") {
      if (wealthBankAccount && optionArray) {
        setSelectedOption(optionArray[0]?.value);
      } else if(wealthBankAccount) {
        const wealthBankingDetailsCollection = data?.basicDetails[data?.currentActiveData?.accountNumber]?.clientProfileDetails[0]?.BankAccountCollection;
        if(wealthBankingDetailsCollection?.length > 0){
          setSelectedOption(wealthBankingDetailsCollection[0].AccountNo)
        }
      }
    }
  }, [data?.currentActiveData?.accountNumber, data?.disbursalDetailsSortedArray, data?.clickaccountNumber]);

  return (
    <>
      <div
        className={`custom-drop-down-account-number ${className ? className : ""
          }`}
      >
        {labelFlag ? (
          <label htmlFor="custom-drop-down-account-details" className={labelClass ? labelClass : ""}>{labelText}{asterisk ? <span className="red-star">*</span> : ""}</label>
        ) : null}

        <div
          className="select-wrapper "
          onClick={(event) => {
            event.stopPropagation()
            setOptionState(!optionState);
          }}
        >
          <p className={placeholderClass}>{selectedOption}</p>
          <img
            style={{
              width: "1.3rem",
              filter: `${themeState === THEME_DARK ? "invert(1)" : ""}`,
              transform: `${optionState ? "rotate(180deg)" : ""}`,
            }}

            src="assets/images/arrow-down.svg"
            alt=''
          />
          {optionState ? (
            <div ref={dropDownRef} className={`custom-drop-down-options-container  ${ccodAccount ? "ccod-account-number" : ""}`}>
              <div className="custom-dropdown-option-inner-container">
                {!bankLas ? optionArray?.map((obj, index) => (
                  <p style={{
                    color: `${themeState === THEME_DARK ? "#fff" : "#000"}`
                  }} key={index} onClick={() => {
                    handdleOptionChange(obj)
                  }}>
                    {obj.value}
                  </p>
                )) : optionArray.filter(obj => obj.system !== "las").map((obj, index) => (
                  <p style={{
                    color: `${themeState === THEME_DARK ? "#fff" : "#000"}`
                  }} key={index} onClick={() => {
                    handdleOptionChange(obj)
                  }} >
                    {obj.value}
                  </p>
                ))}
              </div>
            </div>
          ) : null}

        </div>

      </div>
      {
        errorSpace && (
          <div className="error-span">
            <span>{error}</span>
          </div>
        )
      }
    </>

  );
};


CustomDropDown.propTypes = {
  className: PropTypes.string,
  optionArray: PropTypes.array,
  optionState: PropTypes.bool,
  setOptionState: PropTypes.func,
  selectedOption: PropTypes.string,
  setSelectedOption: PropTypes.func,
  labelFlag: PropTypes.bool,
  labelText: PropTypes.string,
  labelClass: PropTypes.string,
  setActiveTab: PropTypes.func,
  storeSelectAccNumberData: PropTypes.func,
  bankLas: PropTypes.bool,
  setEmployementType: PropTypes.func,
  ccodAccount: PropTypes.bool,
  wealthBankAccount: PropTypes.bool,
  asterisk: PropTypes.bool,
  callGetDisbursalDetails: PropTypes.func,
  error: PropTypes.string,
  errorSpace: PropTypes.bool,
  handleDropDownChange: PropTypes.func,
}

export default CustomDropDown;